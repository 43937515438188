import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { InPageNavigationAwards } from "../components/inpage-navigation"

const Workshops = () => (
    <Layout>
        <Seo title="FEARS Awards" />
        <InPageNavigationAwards/>
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Awards</h1>

                    <p>Multiple different prizes will be awarded to researcher for their excelling research and presentation skills. Below you can read what we are looking for in your contributions for the different awards.</p>
                    <p>Every submission (demo, poster or pitch) was automatically considered for all awards.</p>

                    <h2 id="audience">Audience's choice award</h2>
                    How well can you generate traffic to your poster? How well can you convince a broad audience? (All attendees will be able to vote twice; one time for each poster session.)
                    <div className="row">
                        {/* <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Elias De Ketelaere for his work <a target="_blank" rel="noopener noreferrer" href=""><i>Sustainable Corrosion Inhibition of Carbon Steel With Sodium Silicate</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7400840"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.7400840.svg" alt="DOI"></img></a></p>
                        </div> */}
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>FEARS Organising Committee</p>
                        </div>
                    </div>

                    <h2 id="poster">Best poster award</h2>

                    How do you make your work understandable for laymen? All types of contributions are eligible for this award. Two prizes will be awarded: one for posters and a second one for pitches.
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>Jeroen Ongenae & Roger Van Hecke (FEA Communications Officers)</p>
                        </div>
                    </div>

                    <h2 id="pitch">Best pitch award</h2>

                    How do you make your work understandable for laymen? All types of contributions are eligible for this award. Two prizes will be awarded: one for posters and a second one for pitches.
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>Jolien Coenraets (<a target="_blank" rel="noopener noreferrer" href="https://do.ugent.be/">DO!</a>)</p>
                        </div>
                    </div>

                    <h2 id="interdisciplinary">Award for interdisciplinary research </h2>

                    How are you building bridges between several disciplines?
                    <div className="row">
                        {/* <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>David Francisco Toribio Carvajal with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7401009"><i>Radio Frequency Absorption of a Western Honey Bee In the Near Field of Antennas</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7401009"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.7401009.svg" alt="DOI"></img></a>
                            </p>
                        </div> */}
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>Arnaud Zonderman (<a target="_blank" rel="noopener noreferrer"
                                                    href="https://www.scimingo.eu/nl">SciMingo</a>)</p>
                            {/*<p>Kim Verhaeghe (<a target="_blank" rel="noopener noreferrer"
                                                 href="https://www.eoswetenschap.eu/">EOS</a>)</p>*/}
                        </div>
                    </div>


                    <h2 id="SDG">Award for link to SDGs (Sustainable Development Goals) </h2>

                    Explain where the focus on sustainability lies in your research.
                    <div className="row">
                        {/* <div className="col-sm-7">
                            <h3>Winner: best pitch</h3>
                            <p>Katrien Devos with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7401019"><i>Reuse potential of building materials derived from existing dwellings?</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7401019"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.7401020.svg" alt="DOI"></img></a></p>
                        </div> */}
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>Femke Lootens (<a target="_blank" rel="noopener noreferrer" href="https://www.ugent.be/nl/univgent/missie/duurzaamheidsbeleid/green-office-gent">Green Office</a>)</p>
                        </div>
                    </div>

                    <h2 id="impact">Award for research impact</h2>

                    Which effect does your research have beyond academia?
                    <div className="row">
                        {/* <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Ganjour Mazaev for his work <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7405452"><i>Hybrid Machine Learning for Leak Localization in the Drinking Water Grid</i></a></p>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.7405452"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.7405452.svg" alt="DOI"></img></a>
                        </div> */}
                        <div className="col-sm-12">
                            <h3>Awarded by</h3>
                            <p>Nathan Van Den Bossche (<a target="_blank" rel="noopener noreferrer" href="https://aig.ugent.be/">AIG - Alumni Ingenieurs Gent</a>)</p>
                        </div>
                    </div>

                </article>
            </div>
        </div>
    </Layout>
)

export default Workshops
